<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

  <h4 class="faq" style="color:#97c149">iStories</h4>
<div class="gray">
    <div class="container">
    <h4 class="faq">Vidoes</h4>
        <hr class="hr">

    <div class="row" id="tweets">

    <div class="col-md-4" v-for="istoreVid in istoriesVid" :key="istoreVid.id">
        <router-link :to="'/istoryv/'  +istoreVid.id" style="text-decoration:none; color:#707070">
        <figure class="figure">
        <img  :src="imgUrl+istoreVid.images" class="figure-img img-fluid rounded"  data-toggle="modal" data-target=".bd-example-modal-lg">
        <figcaption class="figure-caption">
        <h5 style="color:#000">{{istoreVid.title}}</h5>
            {{istoreVid.description}}</figcaption>
    <small>{{istoreVid.createdAt}}</small><br><br>
        </figure>

        <!-- Button trigger modal -->

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="padding:20px" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
     <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe width="760" height="515" loading="loading" :src='istoreVid.contentData' title="YouTube video player" frameborder="0"  allowfullscreen></iframe>
      </div>
      
    </div>
  </div>
</div>
        </router-link>
    </div>
  
    </div>
    </div>
    <!-- <div class="showMore">
    <button class="btn btn-dafualt">Show more</button>
    </div> -->
</div>

<div class="gray">
    <div class="container">
    <h4 class="faq">Stories</h4>
        <hr class="hr">

    <div class="row" id="tweets" >
    <div class="col-md-6" v-for="istore in istoriesImg" :key="istore.id" style="margin-bottom:20px">
        <router-link :to="'/istory/'  +istore.id" style="text-decoration:none; color:#707070">
        <div class="row" id="prev" >
        <div class="col-md-7" style="text-align:left; padding:20px">
            <h5>{{istore.title}}</h5>
            <p>{{istore.description}}</p>
            <small style="padding:0px">{{istore.createdAt}}</small>
        </div>
        <div class="col-md-5" id="vidoePrev">
          
            <img  :src='imgUrl+istore.images' alt="" class="img">
        </div>
        </div>
        </router-link>
    </div>
    </div>
    </div>
    <!-- <div class="showMore">
    <button class="btn btn-dafualt">Show more</button>
    </div> -->
</div>

</div>


    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'
import appConfig from "../appConfig";

export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
  data() {
      return {
          istoriesImg:[],
          istoriesVid:[],
          imgUrl: appConfig.baseUrl,
          error:{}
      }
  },
  methods: {
      IstoryText(){
          baseUrl.get('/api/getAllIstories')
          .then((res)=>{
              res.data.map(el=>{
                let option = el.contentType
                if(option =="Text"){
                   this.istoriesImg.push(el)
                }else{
                  this.istoriesVid.push(el)
                }
              })
               
          })
          .catch((err)=>{
              this.error = err
          })
      }
  },
  mounted() {
      this.IstoryText()
  }
}
</script>
<style scoped>
.faqInfo{
margin-top: 180px;
}
#accordion{
padding: 100px;
}
.faq{
padding-left: 100px;
}
/* .hr{
padding-left: 100px;
} */
.gray{
background-color: #F5F7FA;
padding: 60px;
}
.container{
background-color: rbg(236, 235, 235);
}
#tweets{
padding-left: 100px;
padding-right: 100px;
padding-bottom: 20px;
margin-top: 40px;
}
.col-md-4{
border-radius: 10px;
position: relative;

}
small{
padding: 20px;
}
.twiter{
    position: absolute;
    bottom: 370px;
    right: 140px;
  }
.figure{
background-color: rgb(255, 255, 255);
border-radius: 10px;
}
.figure-caption{
padding: 20px;
}
.showMore{
text-align: center;
padding: 50px;
}
.showMore button{
border: 1px solid rgb(170, 165, 165);
padding: 10px;
widows: 200px;
border-radius: 10px;
color: rgb(170, 165, 165);
}
.img{
height: 150px;
width: 165px;
border-radius: 10px;
}
#vidoePrev{
border-radius: 10px;
}
#prev{
background-color: #fff;
border-radius: 10px;
}
.col-md-6{
padding-right: 50px;
}
</style>